.content {
    display: flex;
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 40px;
    max-height: calc(100vh - 80px);
}

.gallery {
    display: flex;
    flex: 1 1 100%;
    position: relative;
    max-width: 100%;
    max-height: 100%;
    flex-direction: column;
    align-items: center;
}

.gallery .photo {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    justify-content: center;
}

.gallery .photo.hidden {
    display: none;
}

.gallery .photo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: 50% 0;

    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-duration: 200ms;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.gallery .photo.reverse img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.navigation {
    position: fixed;
    bottom: 50px;
    left: 50px;
    font-weight: 400;
}

.navigation span {
    cursor: pointer;
}

.navigation span:hover span {
    color: #222;
}

.description {
    font-size: 15px;
    margin-bottom: 20px;
    cursor: pointer;
    text-align: justify;
    white-space: pre-line;
}

.description .read-more-button {
    color: #000;
    text-decoration: underline;
}

.read-more-container {
    font-family: Crimson Text;
    display: flex;
    text-align: justify;
    width: 600px;
    flex-direction: column;
    white-space: pre-line;
}

.read-more-info {
    cursor: pointer;
    margin-bottom: 15px;
    font-weight: 400;
}

.read-more-info:hover {
    color:#222;
}

.back-to-project {
    cursor: pointer;
    align-self: flex-end;
    font-weight: 500;
    text-decoration: underline;
    margin-top: 50px;
}

.about-me {
    margin-top: 7px;
}