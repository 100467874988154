.App {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
  flex: 1;
  /*color: #d3d3d3;*/
  /*color: #222222;*/
  color: rgba(0,0,0,.64);
}

a {
  color: inherit;
  text-decoration: inherit;
}
