.logo {
    font-size: 16px;
    font-weight: 200;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    color: #3e3e3e;
    text-transform: lowercase;
    margin: 25px 0;
}

.logo span:first-child {
    font-weight: 800;
}
