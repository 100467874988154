@media only screen and (max-width: 480px) {
    .navigation {
        display: none;
    }

    .App {
        flex-direction: column;
        height: auto;
    }

    .content {
        max-height: unset;
    }

    .sidebar {
        flex: 1 1;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        padding: 10px 0px 0 0;
        width: 100%;
    }

    .sidebar .project-navigation {
        display: flex;
        flex-direction: row;
    }

    .sidebar .project-navigation .project {
        margin: 0 7px;
    }

    .project-navigation, .about, .contact {
        margin-top: 12px;
    }

    .content {
        padding: 10px;
        max-width: calc(100% - 20px);
    }

    .gallery {
        flex-direction: column;
        align-content: flex-start;
        flex-wrap: nowrap;
    }

    .gallery .photo {
        display: contents;
        /*max-height: 75vh;*/
        height: auto;
    }

    .gallery .photo img {
        height: auto;
        width: 100%;
        margin-bottom: 20px;
        object-fit: unset;
        object-position: unset;
    }

    .scroll-to-top {
        border-radius: 50%;
        padding: 13px 15px;
        border: 1px solid gray;
        color: gray;
    }
}
